:root {
    --square-size: 100px;
}

.game {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.game-board {
    min-width: 860px;
    padding: 20px 30px;
}

.game-info {
    padding: 20px;
}

.board-row:after {
    clear: both;
    content: '';
    display: table;
}

.sq-size {
    width: var(--square-size);
    height: var(--square-size);
}

ol,
ul {
    padding-left: 30px;
}

.status {
    margin-bottom: 10px;
}

.square {
    background: #fff;
    border: 2px solid #555;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    margin-right: -2px;
    margin-top: -2px;
    padding: 0;
    text-align: center;
}

.movescolor {
    background: #8f8;
}

.clickedcolor {
    background: #aaf;
}

.squarecolor {
    background: #999;
}

.can {
    float: left;
    padding: 0;
    margin-right: -2px;
    margin-top: -2px;
}

.white_king {
    background-image: url('icons/white_king.png');
    background-size: contain;
}

.white_queen {
    background-image: url('icons/white_queen.png');
    background-size: contain;
}

.white_rook {
    background-image: url('icons/white_rook.png');
    background-size: contain;
}

.white_bishop {
    background-image: url('icons/white_bishop.png');
    background-size: contain;
}

.white_pawn {
    background-image: url('icons/white_pawn.png');
    background-size: contain;
}

.white_knight {
    background-image: url('icons/white_knight.png');
    background-size: contain;
}

.black_king {
    background-image: url('icons/black_king.png');
    background-size: contain;
}

.black_queen {
    background-image: url('icons/black_queen.png');
    background-size: contain;
}

.black_rook {
    background-image: url('icons/black_rook.png');
    background-size: contain;
}

.black_bishop {
    background-image: url('icons/black_bishop.png');
    background-size: contain;
}

.black_pawn {
    background-image: url('icons/black_pawn.png');
    background-size: contain;
}

.black_knight {
    background-image: url('icons/black_knight.png');
    background-size: contain;
}

.t1 {
    background-image: url('icons/BlackWhite/t1.png');
    background-size: contain;
}

.t2 {
    background-image: url('icons/BlackWhite/t2.png');
    background-size: contain;
}

.t1t1 {
    background-image: url('icons/BlackWhite/t1t1.png');
    background-size: contain;
}

.t2t2 {
    background-image: url('icons/BlackWhite/t2t2.png');
    background-size: contain;
}

.t1t2 {
    background-image: url('icons/BlackWhite/t1t2.png');
    background-size: contain;
}

.t2t1 {
    background-image: url('icons/BlackWhite/t2t1.png');
    background-size: contain;
}

.kbd-navigation .square:focus {
    background: #ddd;
}

li > button {
    width: 180px;
}

.player-info {
    text-align: center;
    font-weight: bold;
    padding: 10px;
    margin-bottom: 10px;
}
