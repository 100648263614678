.nav-link.active,
.nav-link:hover {
    color: #4caf50 !important;
    outline: none;
}
.nav-link {
    color: #6c757d;
}

.tab-content {
    margin-top: 15px;
}

.card-header {
    font-size: larger;
    text-align: left;
}

.points {
    float: right;
}

.game-win {
    background-color: #4caf50;
}
.game-lose {
    background-color:red;
}
.game-draw {
    background-color:grey;
}

.background-rank0 {
    background-color: gold;
}

.background-rank1 {
    background-color: silver;
}

.background-rank2 {
    background-color: #bf8970;
}

.card-collapse {
    margin-top: 15px;
}

.nav-search-button {
    margin-left: 10px;
}

.accord-card-header {
    margin-bottom: 20px;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin-top: 100px;
    border: 8px solid #dfc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #dfc transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .link-font-color {
    color: black;
  }

  xmp{ white-space:pre-wrap; word-wrap:break-word; }
  