body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// colors
$gray: #444;
$light-gray: #ccc8c8;
$medium-gray: #666;
$black: #333;
$white: #fff;
$green: #0f8e51;
$blue: #01ade4;

html,
body,
#root,
.App {
  height: 100%;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: Lato, sans-serif;
  font-size: 17px;
  color: $black;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300 !important;
}

.wrapper {
  position: relative;

  h1 {
    position: fixed;
    top: 0;
    width: 100%;
    background: $blue;
    color: $white;
    font-weight: 300;
    margin-top: 0;
    padding: 10px 0;
    text-align: center;
    max-height: 60px;
    z-index: 2;
  }
}

.main {
  flex: 1 1 40%;
  display: block;
  padding: 0 1em;
  margin-top: 100px;
  margin-left: 50px;
}

.sidebar {
  flex: 1 1 20%;
  padding: 0 3%;
}

.board {
  height: 808px;
  width: 808px;
  // flex: 1 1 60%;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  margin: auto;
}

.row {
  width: 100%;
  display: flex;

  &:nth-child(odd) .square:nth-child(even) {
    background: $light-gray;
  }

  &:nth-child(even) .square:nth-child(odd) {
    background: $light-gray;
  }
}

.square {
  height: 100px;
  width: 100px;
  //flex: 1 1 0;
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid $black;
  min-height: 100px;
  position: relative;

  .square-meta {
    display: flex;
    justify-content: space-between;
  }

  .chess-id {
    font-size: 75%;
    background-color: $green;
    color: $white;
    padding: 0.2em;
  }

  .delete-label {
    padding-right: 5px;

    &::before {
      content: "x";
      color: $gray;
      font-size: 75%;
      height: 10px;
      width: 10px;
      cursor: pointer;
    }
  }

  .piece {
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    font-size: 3em;
    cursor: pointer;
  }
}

.delete-form {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background: $blue;

  & p {
    margin-bottom: 0;
  }
}

.command-form {
  h3 {
    margin-top: 0;
  }

  input,
  button {
    display: block;
    width: 100%;
    font-size: 1.2em;
    font-weight: 300;
    outline: 0;
    padding: 0.4em;
  }

  input {
    margin-bottom: 10px;
  }

  button {
    background: $green;
    border: 0;
    color: $white;
    cursor: pointer;

    &:hover {
      background: darken($green, 8%);
    }
  }

  p {
    font-size: 85%;
  }
}

.piece-lists {
  margin-top: 40px;
}

.piece-list {
  width: 100%;
  border: 1px solid $black;
  margin-bottom: 20px;
  border-collapse: collapse;

  th {
    width: 100%;
    text-align: left;
    padding: 0.5em;
    background: $medium-gray;
    color: $white;
    font-weight: 300;
  }

  tr {
    &:nth-child(even) {
      background: $light-gray;
    }
  }

  td {
    padding: 0.5em;
  }
}

.notices {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  .notice {
    display: block;
    background: $blue;
    color: $white;
    text-align: center;
    font-size: 2em;
    padding: 2em;

    & span {
      font-size: 85%;
      font-weight: 300;
    }
  }

  &.isHidden {
    display: none;
  }
}
