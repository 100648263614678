.game {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  max-width: 1200px;
}

#spacing {
  display: block;
  height: 30px;
}

.gameInfo {
  width: 350px;
}