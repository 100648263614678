:root {
  --abs-black: rgba(0, 0, 0, 0);;
  --black: rgba(0, 0, 0, 0.801);
  --white: rgb(220, 220, 220);
  --abs-white: rgb(255, 255, 255);;
}

.blackPlayer {
  background-color: var(--black);
  color: var(--white);
}

.whitePlayer {
  background-color: var(--white);
  color: var(--black);
}